<template>
  <div class="h5-solution-list">
    <van-sticky>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="categoryVal"
          :options="categoryList"
          @closed="categoryChange"
        />
        <van-dropdown-item
          v-model="categorySubVal"
          :options="categorySubList"
          @closed="categorySubChange"
        />
        <van-dropdown-item v-model="heatVal" :options="heatList" @closed="heatChange"/>
      </van-dropdown-menu>
    </van-sticky>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="h5-solution-list-box">
        <div v-for="i in solutionList" :key="i.id" @click="solutionInfo(i.id)">
          <span>
            <span v-if="i.marketing == 2">推荐</span>
            <span v-if="i.marketing == 3">HOT</span>
            <v-img :attachment-id="i.solutionImg " :default-img="require('@/assets/img/kctp.png')" />
          </span>
          <div>
            <p>{{i.name}}</p>
            <div>{{i.solSlogan}}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getCategoryList,getSolutionList } from "@/api/solution";
import VImg from "@/components/VImg";
export default {
  components:{
    VImg
  },
  data() {
    return {
      categoryData:[],
      categoryVal: "",
      categorySubVal: "",
      heatVal: "",
      categoryList: [{ text: "所有分类", value: "" }],
      categorySubList: [{ text: "二级分类", value: "" }],
      heatList: [
        { text: "全部", value: "" },
        { text: "推荐", value: "2" },
        { text: "火热", value: "3" },
      ],
      categoryId:'',
       page: {
        current: 0,
        size: 10,
        pageSize: 1,
      },
      loading: false,
      finished: false,
      solutionList:[]
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.categoryVal = this.$route.query.pid;
      this.categorySubVal = this.$route.query.id;
      this.categoryId = this.$route.query.id;
    }
    // this.loadsolutionList();
    this.loadGetSolutionCategory();
  },
  methods: {
    onLoad(){
      if(this.page.pageSize == this.page.current){
        this.finished = true;
        return
      }
      this.page.current ++
      this.loading = true
      this.loadsolutionList();
    },
    loadsolutionList(){
      let { page,categoryId,heatVal,categorySubVal } = this;
      if(categorySubVal != ''){
        categoryId = categorySubVal
      }
      let data = {
        status: "",
        marketing: heatVal,
        tag: "",
        category:categoryId,
      };
      getSolutionList(page, data).then((res) => {
        this.loading = false;
        this.solutionList = [...this.solutionList,...res.records];
        this.page.pageSize = res.pages;
        if(res.pages <= res.current){
          this.finished = true;
          return
        }
        this.finished = false;
      });
    },
    // 类别列表
    loadGetSolutionCategory() {
      getCategoryList("1").then((res) => {
        let data = res;
        data.forEach((element) => {
          element.value = element.id;
          element.text = element.name;
          element.children.forEach((item) => {
            item.value = item.id;
            item.text = item.name;
            this.categorySubList.push(item);
          });
        });
        this.categoryData = data;
        this.categoryList = [...this.categoryList, ...data];
      });
    },

    categoryChange(){
      let e = this.categoryVal
      this.categoryId = e
      this.categoryVal = e;
      this.categorySubVal = ''
      let { categoryList, categoryData } = this;
      this.categorySubList = [{ text: "二级分类", value: "" }];
      if (e != '') {
        categoryList.forEach(element => {
          if(element.id == e){
            element.children.forEach((item) => {
              this.categorySubList.push(item);
            });
          }
        });
      } else {
        categoryData.forEach((element) => {
          element.children.forEach((item) => {
            this.categorySubList.push(item);
          });
        });
      }
      this.page.current = 1
      this.solutionList = []
      this.finished = false
      this.loadsolutionList();
    },
    // 选择二级类别
    categorySubChange() {
      let {categorySubList,categorySubVal} = this
      let e = categorySubVal
      let pid = ''
      categorySubList.forEach(element => {
        if(element.id == e){
          pid = element.pid
        }
      });
      this.categorySubVal = e;
      this.categoryVal = pid
      if(e != ''){
        this.categoryId = e
      }else{
        this.categoryId = ''
      }
      this.page.current = 1
      this.solutionList = []
      this.finished = false
      this.loadsolutionList();
    },
    heatChange(){
      this.page.current = 1
      this.solutionList = []
      this.finished = false
      this.loadsolutionList();
    },
    solutionInfo(id,type) {
      // type  1 常规方案，2 3 鸿蒙方案， 4 小红书方案 5 普通方案 6数字方案
      // 1 ， 2 3，4 5，6
      console.log(id,'type')
      if(type == 2 || type == 3){
        this.$router.push({
          path: "/solution/info",
          query: { id },
        });
      }else if(type==6){
        this.$router.push({
          path: "/solution/digitizing",
          query: { id },
        });
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.h5-solution-list {
  min-height: 100%;
  background: #f6f6f6;
}
.h5-solution-list-box {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    background: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    // overflow: hidden;
    margin-bottom: 10px;
    width: 168px;
    > span {
      display: block;
      position: relative;
      >span{
        background: url(../../assets/img/rd.png) no-repeat;
        background-size: 100% 100%;
        display: block;
        width: 32px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        padding-left: 2px;
        position: absolute;
        left: 10px;
        top: -4px;
      }
      > img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        height: 108px;
      }
    }
    > div {
      padding: 12px 8px 24px;
      > p {
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > div {
        margin-top: 10px;
        font-size: 12px;
        color: #999;
        line-height: 16px;
      }
    }
  }
}
</style>
<style>
.h5-solution-list .van-dropdown-menu__bar {
  height: 44px;
}
</style>
